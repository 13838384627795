export const ROUTES = {
  root: "/",
  interactive: "/interactive",

  quiz: "/taplalkozasi-elemzes",
  quizThanks: "/taplalkozasi-elemzes-koszonjuk",
  quizResults: "/taplalkozasi-elemzes-eredmeny/:id",
  getQuizResults: ({ id }: { id: string }) =>
    "/taplalkozasi-elemzes-eredmeny/" + id,

  login: "/login",
  register: "/register",
  confirmEmail: "/confirm-email/:token?",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:token?",
  terms: "/terms",
  privacy: "/privacy",

  presentation: {
    kBase: "/knowledge-base",
    recipes: "/recipes",
    professionals: "/meet-the-team",
    gift: "/ajandek",
  },

  subsciptions: "/subscriptions/",
  getSubscriptions: ({ error, code }: { error?: string; code?: string }) => {
    if (code) return "/subscriptions/?affiliate=" + code;

    return "/subscriptions/?error=" + error;
  },
  payment: "/payment",
  directPayment: "/direct-payment",
  getPayment: ({ plan }: { plan?: string }) => {
    if (plan) return "/payment/" + plan;

    return "/payment";
  },
  paymentConfirmation: "/payment-confirmation",

  retryPayment: "/retry-payment",

  appRoot: "/app",
  recipes: {
    root: "/app/recipes",
    recipe: "/app/recipes/recipe/:id",
    getRecipe: ({ id }: { id: string }) => "/app/recipes/recipe/" + id,
    saved: "/app/recipes/saved",
  },
  kBase: {
    root: "/app/knowledge-base",
    course: "/app/knowledge-base/course/:id",
    getCourse: ({ id }: { id: string }) => "/app/knowledge-base/course/" + id,

    module: "/app/knowledge-base/module/:id/video/:videoId?",
    getModule: ({ id, videoId }: { id: string; videoId?: string }) =>
      "/app/knowledge-base/module/" + id + "/video/" + videoId,

    lecturer: "/app/knowledge-base/lecturer/:id",
    getLecturer: ({ id }: { id: string }) =>
      "/app/knowledge-base/lecturer/" + id,
  },

  admin: {
    root: "/admin",
    kBase: {
      root: "/admin/knowledge-base",
      course: "/admin/knowledge-base/course/:id",
      getCourse: ({ id }: { id: string }) =>
        "/admin/knowledge-base/course/" + id,
      newCourse: "/admin/knowledge-base/course/new",

      module: "/admin/knowledge-base/module/:id",
      getModule: ({ id }: { id: string }) =>
        "/admin/knowledge-base/module/" + id,
      newModule: "/admin/knowledge-base/module/new",

      video: "/admin/knowledge-base/video/:id",
      getVideo: ({ id }: { id: string }) => "/admin/knowledge-base/video/" + id,
      newVideo: "/admin/knowledge-base/video/new",

      lecturers: "/admin/knowledge-base/lecturers",
      lecturer: "/admin/knowledge-base/lecturer/:id",
      getLecturer: ({ id }: { id: string }) =>
        "/admin/knowledge-base/lecturer/" + id,
      newLecturer: "/admin/knowledge-base/lecturer/new",
    },
    recipes: {
      recipe: "/admin/recipes/:id",
      getRecipe: ({ id }: { id: string }) => "/admin/recipes/" + id,
      newRecipe: "/admin/recipes/new",
    },
    subscriptions: {
      root: "/admin/subscriptions",
      subscription: "/admin/subscriptions/:id",
      getSubscription: ({ id }: { id: string }) => "/admin/subscriptions/" + id,
      newSubscription: "/admin/subscriptions/new",
    },
    users: {
      root: "/admin/users",
      user: "/admin/users/:id",
      getUser: ({ id }: { id: string }) => "/admin/users/" + id,
    },
    coupons: {
      root: "/admin/coupons",
      coupon: "/admin/coupons/:id",
      getCoupon: ({ id }: { id: string }) => "/admin/coupons/" + id,
      newCoupon: "/admin/coupons/new",
    },
    analysis: {
      root: "/admin/analysis",
      data: "/admin/analysis/:id",
      getData: ({ id }: { id: string }) => "/admin/analysis/" + id,
    },
    ebooks: {
      newEbook: "/admin/ebooks/new",
      ebook: "/admin/ebooks/:id",
      getEbook: ({ id }: { id: string }) => "/admin/ebooks/" + id,
    },
  },

  profile: {
    root: "/app/profile",
    edit: "/app/profile/edit",
    changePassword: "/app/profile/change-password",
    manageSubscription: "/app/profile/manage-subscription",
  },

  chat: {
    root: "/app/chat",
    chat: "/app/chat/:id",
    getChat: ({ id }: { id: string }) => "/app/chat/" + id,
  },

  ebooks: {
    root: "/app/ebooks",
    ebook: "/app/ebooks/:id",
    getEbook: ({ id }: { id: string }) => "/app/ebooks/" + id,
  },
};
