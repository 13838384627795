import { FormEvent, useEffect, useState } from "react";
import Layout from "../../../../components/Layout";
import { Image, Spacer, Spinner, Switch } from "@nextui-org/react";
import { useDropzone } from "react-dropzone";
import { IconDragDrop, IconDragDrop2 } from "@tabler/icons-react";
import { getImageUrl } from "../../../../utils/images.utils";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getEbookById } from "../../../../api/app/ebooks.api";
import { createEbook, updateEbook } from "../../../../api/admin/app/ebooks.api";
import { ROUTES } from "../../../../constants/routes";
import { Button } from "../../../../components/Button";

export default function AdminEbook({ isNew }: { isNew?: boolean }) {
  const [active, setIsActive] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      multiple: false,
      accept: { "image/*": [] },
    });

  const {
    acceptedFiles: acceptedEbook,
    getRootProps: getRootPropsEbook,
    getInputProps: getInputPropsEbook,
    isDragActive: isDragActiveEbook,
  } = useDropzone({
    multiple: false,
    accept: { "application/pdf": [".pdf"] },
  });

  const { isLoading: isEbookLoading, data: ebook } = useQuery(
    ["ebook", id],
    () => getEbookById(id!),
    {
      enabled: !isNew,
    },
  );

  useEffect(() => {
    if (!isNew && ebook) {
      setIsActive(ebook.status === "ACTIVE" ? true : false);
      if (acceptedFiles.length === 0 && acceptedEbook.length === 0) {
        // Assuming ebook.image is a URL or path to the image
        fetch(ebook.image)
          .then((res) => res.blob())
          .then((blob) => {
            const imageFile = new File([blob], ebook.image);
            acceptedFiles.push(imageFile); // or whatever method this library uses
            acceptedEbook.length = 0;
          });
        fetch(ebook.file)
          .then((res) => res.blob())
          .then((blob) => {
            const ebookFile = new File([blob], ebook.file);
            acceptedEbook.push(ebookFile); // or whatever method this library uses
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebook]);

  // useEffect(() => {
  //   if (!isNew && ebook) {
  //     if (acceptedEbook.length === 0) {
  //       // Assuming ebook.file is a URL or path to the file
  //       fetch(ebook.file)
  //         .then((res) => res.blob())
  //         .then((blob) => {
  //           const ebookFile = new File([blob], ebook.file);
  //           acceptedEbook.push(ebookFile); // or whatever method this library uses
  //         });
  //     }
  //   }
  // }, [ebook]);
  useEffect(() => {
    return () => {
      while (acceptedEbook.length > 0) {
        acceptedEbook.pop();
      }
      while (acceptedFiles.length > 0) {
        acceptedFiles.pop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: updateIsLoading, mutate: updateEbookData } = useMutation(
    updateEbook,
    {
      onError: (error: { message: string }) => {
        setError(error.message);
      },
      onSuccess: () => {
        acceptedEbook.pop();
        acceptedFiles.pop();
        navigate(ROUTES.ebooks.root);
      },
    },
  );

  const { isLoading: createIsLoading, mutate: createEbookData } = useMutation(
    createEbook,
    {
      onError: (error: { message: string }) => {
        setError(error.message);
      },
      onSuccess: () => {
        acceptedEbook.pop();
        acceptedFiles.pop();
        navigate(ROUTES.ebooks.root);
      },
    },
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isNew) {
      createEbookData({
        file: acceptedEbook,
        image: acceptedFiles,
        active,
      });
    } else {
      updateEbookData({
        id: ebook.id,
        active,
      });
    }
  };

  useEffect(() => {
    setIsLoading(updateIsLoading || createIsLoading || isEbookLoading);
  }, [updateIsLoading, createIsLoading, isEbookLoading]);

  if (!isNew && isEbookLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter classNames={{ main: "max-w-screen-sm" }}>
      <form onSubmit={onSubmit}>
        <h1 className="mt-4 text-xl font-semibold">Ebook hozzáadása</h1>
        <Spacer y={4} />
        <Switch
          onValueChange={(isSelected) => setIsActive(isSelected)}
          isSelected={active}
          className="mt-4"
        >
          <span className="max-w opacity-70">Aktív</span>
        </Switch>

        <Spacer y={4} />
        {isNew ? (
          <>
            <div
              {...getRootProps()}
              className="mt-4 flex flex-col items-center justify-center rounded-xl border-2 border-solid border-default-200 p-3 shadow-sm "
            >
              <h1 className="self-start pb-1 text-sm text-primary-300">
                Borítókép
              </h1>
              <input {...getInputProps()} />
              {isDragActive && (
                <div
                  className={
                    (acceptedFiles.length ? "h-80" : "") +
                    " flex flex-col items-center justify-center"
                  }
                >
                  <IconDragDrop2 size={48} className="text-primary-500" />
                  <p className="text-center text-sm text-primary-300">
                    Engedd el a fájlt a feltöltéshez
                  </p>
                </div>
              )}
              {isDragActive ||
                (!acceptedFiles.length && (
                  <>
                    <IconDragDrop size={48} className="text-primary-300" />
                    <p className="text-sm text-primary-300">
                      Húzd ide a fájlt vagy kattints a feltöltéshez
                    </p>
                  </>
                ))}
              {!isDragActive && acceptedFiles.length > 0 && (
                <div>
                  {acceptedFiles.map((file) => (
                    <Image
                      key={file.name}
                      src={
                        file.name.includes("uploads")
                          ? getImageUrl(file.name)
                          : URL.createObjectURL(file)
                      }
                      alt={file.name}
                      className="max-h-80 rounded-lg"
                    />
                  ))}
                </div>
              )}
            </div>

            <Spacer y={4} />
            <div
              {...getRootPropsEbook()}
              className="mt-4 flex flex-col items-center justify-center rounded-xl border-2 border-solid border-default-200 p-3 shadow-sm "
            >
              <h1 className="self-start pb-1 text-sm text-primary-300">
                Ebook
              </h1>
              <input {...getInputPropsEbook()} />
              {isDragActiveEbook && (
                <div
                  className={
                    (acceptedEbook.length ? "h-80" : "") +
                    " flex flex-col items-center justify-center"
                  }
                >
                  <IconDragDrop2 size={48} className="text-primary-500" />
                  <p className="text-center text-sm text-primary-300">
                    Engedd el a fájlt a feltöltéshez
                  </p>
                </div>
              )}
              {isDragActiveEbook ||
                (!acceptedEbook.length && (
                  <>
                    <IconDragDrop size={48} className="text-primary-300" />
                    <p className="text-sm text-primary-300">
                      Húzd ide a fájlt vagy kattints a feltöltéshez
                    </p>
                  </>
                ))}
              {!isDragActiveEbook && acceptedEbook.length > 0 && (
                <div>
                  {acceptedEbook.map((file) => (
                    <h1 key={file.name} className="text-primary-300">
                      {file.name}
                    </h1>
                  ))}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="flex w-full items-center justify-center">
            <Image
              src={getImageUrl(ebook?.image)}
              alt={ebook?.title}
              className="max-h-80 rounded-lg"
            />
          </div>
        )}
        {error && (
          <>
            <Spacer y={3} />
            <p className="text-center text-sm text-red-500">{error}</p>
          </>
        )}

        <Spacer y={4} />

        <Button
          variant="solid"
          color="primary"
          className="mt-8 w-full"
          type="submit"
          isLoading={isLoading}
          isDisabled={
            isLoading ||
            acceptedFiles.length === 0 ||
            acceptedEbook.length === 0
          }
        >
          Mentés
        </Button>

        <Spacer y={4} />
      </form>
    </Layout>
  );
}
