import { RouteObject } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import EbooksRoot from "../pages/app/ebook";
import Ebook from "../pages/app/ebook/ebook";

export const EbookRouter: RouteObject[] = [
  {
    path: ROUTES.ebooks.root,
    element: <EbooksRoot />,
  },
  {
    path: ROUTES.ebooks.ebook,
    element: <Ebook />,
  },
];
