import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ReactPlayer from "react-player";
import { IconBrandFacebook } from "@tabler/icons-react";
import { APP_DOMAIN } from "../../constants/environment";

export default function Gift() {
  return (
    <>
      <Header />
      <section className="bg-primary-300">
        <h1 className="px-4 py-12 text-center text-4xl font-bold md:text-5xl">
          Bizonyítottan hatásos kiegészítők
        </h1>
      </section>
      <section className=" bg-gradient-to-b from-primary-100 py-8">
        <div className="container mx-auto my-8 max-w-4xl px-4">
          <ReactPlayer
            controls
            width={"100%"}
            height={"100%"}
            playsinline
            url={
              "https://storage.googleapis.com/nutriqueens-app/Bizonyitottan%20hasznos%20kiegeszitok.mp4"
            }
          />
          <p className="mx-auto mt-12 max-w-2xl text-center text-xl">
            Csatlakozz az ingyenes életmódváltó facebook csoportomhoz, ha még
            több bizonyítottan hasznos információt, tudást, motivációt szeretnél
            a fogyásodhoz!
          </p>
          <a
            href="https://www.facebook.com/groups/1124689249013980"
            target="_blank"
            className="mx-auto mt-6 flex w-fit cursor-pointer flex-row items-center justify-center gap-2  rounded-xl bg-primary-500 p-2 px-4"
          >
            <p className="max-w-2xl text-center text-white">
              Csatlakozz a díjmentes női életmódváltó közösséghez
            </p>
            <IconBrandFacebook className="h-8 w-8 text-white" />
          </a>
          <a
            href={`${APP_DOMAIN}/${encodeURIComponent("Forrasok- Bizonyitottan hasznos kiegeszitok.pdf")}`}
            target="_blank"
            className="mx-auto mt-12 flex w-fit cursor-pointer p-2 px-4"
          >
            Források
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
}
