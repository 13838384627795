import { API_URL } from "../../../constants/environment";
import { axios } from "../../axios";

export async function createEbook({
  file,
  image,
  active,
}: {
  file: File[];
  image: File[];
  active?: boolean;
}) {
  const form = new FormData();

  for (const f of file) {
    if (f instanceof File) {
      form.append("ebooks", f);
    }
  }

  for (const i of image) {
    if (i instanceof File) {
      form.append("images", i);
    }
  }

  if (active) form.append("active", active.toString());

  const response = await axios.post(`${API_URL}/admin/ebooks`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}

export async function updateEbook({
  id,
  active,
}: {
  id: string;
  active?: boolean;
}) {
  const response = await axios.put(`${API_URL}/admin/ebooks/${id}`, {
    active,
  });

  if (response.status == 200) {
    return response.data;
  }

  throw response.data;
}
