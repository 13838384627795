import Layout from "../../../components/Layout";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getEbookById } from "../../../api/app/ebooks.api";
import { Button, Spacer, Spinner } from "@nextui-org/react";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import { getImageUrl } from "../../../utils/images.utils";
import { pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { useAuth } from "../../../store/auth.store";
import { IconPencil } from "@tabler/icons-react";
import { ROUTES } from "../../../constants/routes";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const AddButton = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const auth = useAuth();

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex cursor-pointer items-center justify-center pt-3 opacity-70">
        <Button
          startContent={<IconPencil />}
          variant="light"
          color="default"
          onClick={() => navigate(ROUTES.admin.ebooks.getEbook({ id: id! }))}
        >
          Ebook móódosítása
        </Button>
      </div>
    );
  }

  return null;
};

export default function Ebook() {
  const [numPages, setNumPages] = useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const { id } = useParams();
  const { isLoading, data } = useQuery(["ebook", id], () => getEbookById(id!), {
    enabled: !!id,
  });

  if (isLoading)
    return (
      <Layout>
        <Spinner />
      </Layout>
    );

  return (
    <Layout>
      <AddButton />
      <Spacer y={4} />
      <Document
        file={getImageUrl(data?.file)}
        onLoadSuccess={onDocumentLoadSuccess}
        className={"min-h-[calc(100vh+64px)]"}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className="select-none"
            width={window.innerWidth > 800 ? 800 : window.innerWidth - 32}
          />
        ))}
      </Document>
    </Layout>
  );
}
