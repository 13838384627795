import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import { useAuth } from "../../../store/auth.store";
import { Button } from "../../../components/Button";
import { IconMoodSad, IconPencilPlus } from "@tabler/icons-react";
import { ROUTES } from "../../../constants/routes";
import { Chip, Image, Spacer, Spinner } from "@nextui-org/react";
import { useQuery } from "react-query";
import { getEbooks } from "../../../api/app/ebooks.api";
import { getImageUrl } from "../../../utils/images.utils";

const AddButton = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  if (auth.user!.role === "ADMIN" || auth.user!.role === "OWNER") {
    return (
      <div className="flex cursor-pointer items-center justify-center pt-3 opacity-70">
        <Button
          startContent={<IconPencilPlus />}
          variant="light"
          color="default"
          onClick={() => navigate(ROUTES.admin.ebooks.newEbook)}
        >
          Ebook hozzáadása
        </Button>
      </div>
    );
  }

  return null;
};

export default function EbooksRoot() {
  const navigate = useNavigate();
  const { isLoading, data } = useQuery("ebooks", () => getEbooks(), {
    refetchOnWindowFocus: false,
  });

  if (isLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  if (!data || data.length === 0)
    return (
      <Layout showFooter>
        <AddButton />
        <h1 className="mb-4 mt-6 text-center text-2xl font-semibold opacity-75">
          Ebook-ok
        </h1>
        <Spacer y={16} />
        <div className="flex flex-col items-center justify-center">
          <IconMoodSad size={100} className="text-center text-secondary-200" />
          <Spacer y={2} />
          <h2 className="text-center text-lg opacity-85">
            Jelenleg nincs elérhető ebook.
          </h2>
          <h3 className="mt-2 text-center text-sm opacity-70">
            Látogass vissza később!
          </h3>
        </div>
        <Spacer y={40} />
      </Layout>
    );

  return (
    <Layout showFooter>
      <AddButton />
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="mt-6 text-2xl font-semibold opacity-75">Ebook-ok</h1>
        <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-3">
          {data.map((ebook: { status: string; image: string; id: string }) => {
            return (
              <div className="relative cursor-pointer">
                {ebook.status === "INACTIVE" && (
                  <Chip color="default" className="absolute left-2 top-2 z-20">
                    Nem publikus
                  </Chip>
                )}
                <Image
                  src={getImageUrl(ebook.image)}
                  className="w-full"
                  onClick={() =>
                    navigate(ROUTES.ebooks.getEbook({ id: ebook.id }))
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
