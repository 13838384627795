import { useQuery } from "react-query";
import Layout from "../../components/Layout";
import { getVideoToPlay } from "../../api/app/home.api";
import { Card, CardBody, CardFooter, Image, Spinner } from "@nextui-org/react";
import { getImageUrl } from "../../utils/images.utils";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import {
  IconDiscountCheckFilled,
  IconMessage,
  IconPlayerPlayFilled,
} from "@tabler/icons-react";
import Ebook from "../../assets/ebook.svg";
import Recipes from "../../assets/recipes.svg";
import Videos from "../../assets/kBase.svg";

export default function AppHome() {
  const navigate = useNavigate();

  const { isLoading, data: videoToPlay } = useQuery(
    "videoToPlay",
    getVideoToPlay,
    {
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading)
    return (
      <Layout
        showFooter
        classNames={{
          main: "flex justify-center items-center h-screen",
        }}
      >
        <Spinner />
      </Layout>
    );

  return (
    <Layout showFooter>
      {videoToPlay && !videoToPlay.message && (
        <div className="mt-12 flex flex-col">
          {/* <h1 className="text-lg font-semibold opacity-70 mb-2">Folytasd a nezest:</h1> */}
          <Card
            isFooterBlurred
            isPressable
            isHoverable
            disableRipple
            classNames={{
              base: "overflow-hidden text-white",
            }}
            onPress={() =>
              navigate(
                ROUTES.kBase.getModule({
                  id: videoToPlay?.module?.id,
                  videoId: videoToPlay?.id,
                }),
                { state: { courseId: videoToPlay?.module?.courseId } },
              )
            }
          >
            <CardBody className="relative overflow-visible p-0">
              <Image
                src={getImageUrl(videoToPlay?.module?.image)}
                alt={videoToPlay?.module?.title}
                radius="none"
                classNames={{
                  img: "w-full",
                  wrapper: "w-full !max-w-full h-40 flex items-center",
                }}
              />
            </CardBody>
            <CardFooter className="z-10 flex flex-col p-0">
              <div className="flex w-full flex-row items-center justify-between">
                <h2 className="p-3 text-xl font-medium">
                  {videoToPlay?.title}
                </h2>
                <IconPlayerPlayFilled size={24} className="mr-3" />
              </div>
            </CardFooter>
          </Card>
        </div>
      )}
      {!videoToPlay ||
        (videoToPlay.message === "No more videos" && (
          <div className="mt-4 flex flex-col">
            {/* <h1 className="text-lg font-semibold opacity-70 mb-2">
              Folytasd a nezest:
            </h1> */}
            <Card
              isFooterBlurred
              isHoverable
              classNames={{
                base: "overflow-hidden",
              }}
            >
              <CardBody className="relative overflow-visible p-0">
                <div className="flex h-36 w-full items-center justify-center">
                  <p className="text-xl font-semibold">
                    Minden videót végig néztél. Gratulalunk!
                  </p>
                </div>
              </CardBody>
              <CardFooter className="z-10 flex flex-col p-0">
                <div className="flex w-full flex-row items-center justify-center">
                  <IconDiscountCheckFilled
                    size={48}
                    className="mb-3 fill-primary-300"
                  />
                </div>
              </CardFooter>
            </Card>
          </div>
        ))}
      <div className="mb-12 mt-12 grid grid-cols-1 gap-6 md:grid-cols-3">
        <Card
          isHoverable
          isPressable
          className="cursor-pointer"
          onPress={() => navigate(ROUTES.kBase.root)}
        >
          <CardBody className="flex flex-row items-center justify-center">
            <h2 className="w-full text-center text-2xl font-bold leading-tight">
              Tudástár
            </h2>
            <Image
              src={Videos}
              alt="Recipes"
              classNames={{
                img: "h-24",
              }}
            />
          </CardBody>
        </Card>
        <Card
          isHoverable
          isPressable
          className="cursor-pointer"
          onPress={() => navigate(ROUTES.recipes.root)}
        >
          <CardBody className="flex flex-row items-center justify-center">
            <h2 className="w-full text-center text-2xl font-bold leading-tight">
              Receptek
            </h2>
            <Image
              src={Recipes}
              alt="Recipes"
              classNames={{
                img: "h-24",
              }}
            />
          </CardBody>
        </Card>
        <Card
          isHoverable
          isPressable
          className="cursor-pointer"
          onPress={() => navigate(ROUTES.ebooks.root)}
        >
          <CardBody className="flex flex-row items-center justify-center overflow-hidden">
            <h2 className="w-full text-center text-2xl font-bold leading-tight">
              Ebook-ok
            </h2>
            <Image
              src={Ebook}
              alt="Ebook"
              classNames={{
                img: "h-24",
              }}
            />
          </CardBody>
        </Card>
        <Card
          isHoverable
          isPressable
          className="cursor-pointer bg-gradient-to-br from-accent-200 to-primary-300 duration-100 ease-in-out transition-transform-background hover:from-accent-300 hover:to-primary-400 md:col-span-3"
          onPress={() => navigate(ROUTES.chat.root)}
        >
          <CardBody className="flex flex-row items-center justify-center overflow-hidden">
            <h2 className="w-full text-center text-2xl font-bold leading-tight">
              Chat
            </h2>
            <IconMessage size={48} className="mr-4 stroke-accent-500" />
          </CardBody>
        </Card>
      </div>
    </Layout>
  );
}
